* {
  font-family: "Roboto";
}

body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100%;
  margin-top: 10vh;
  background-color: #e5e5e5;
}

@media only screen and (min-width: 1024px) {
  body {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    height: 100%;
    margin-left: 10vh;
  }
}

.main-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 50vh;
  color: #0e1116;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter,
.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 1000ms ease-in;
  transition: opacity 1000ms ease-in;
}
/*# sourceMappingURL=mainStyle.css.map */